import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import "./confirmation-record.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header_connect"
import { resetRegistration } from "../redux/registration/registrationAction";

const RecordRegistrationPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetRegistration())
        return () => {
            dispatch(resetRegistration())
        }
    }, [])

    return (
        <Layout main={false}>
            <SEO title="Registration" />
            <Header />
            <div className={"page"}>
                <h2 className={"page-title"}>Your registration has been received</h2>

                <p>
                    Thank you for your registration you will receive a confirmation email soon.</p>
                <p>
                    <p>Union World Conference Registration Team</p>
                </p>
            </div>
            <div className={"content-footer footer-alone"}>
            </div>
        </Layout>
    )
}

export default RecordRegistrationPage
